/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #efefef;
  transition: background-color 0.4s, box-shadow 0.4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 8px;
  left: 0.3em;
  bottom: 0.3em;
  transform: rotate(270deg);
  background-color: rgb(255, 255, 255);
  transition: transform 0.4s;
}

.switch input:checked + .slider {
  background-color: #efefef;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #d9d9d9;
}

.switch input:checked + .slider:before {
  transform: translateX(1.5em);
  background-color: #24445a;
}

.inactive-text {
  color: #808080;
}

.flex .switch input:checked + .slider + p {
  color: #24445a;
  font-weight: 600;
}

.slider:before {
  background-color: #24445a;
}

@media (max-width: 767px) {
  .switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 1.5em;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #efefef;
    transition: background-color 0.4s, box-shadow 0.4s;
    border-radius: 10px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1.2em;
    width: 1.2em;
    border-radius: 8px;
    left: 0.4em;
    bottom: 0.1em;
    transform: rotate(270deg);
    background-color: rgb(255, 255, 255);
    transition: transform 0.4s;
  }

  .switch input:checked + .slider {
    background-color: #efefef;
  }

  .switch input:focus + .slider {
    box-shadow: 0 0 1px #d9d9d9;
  }

  .switch input:checked + .slider:before {
    transform: translateX(1.5em);
    background-color: #24445a;
  }

  .inactive-text {
    color: #808080;
  }

  .flex .switch input:checked + .slider + p {
    color: #24445a;
    font-weight: 600;
  }

  .slider:before {
    background-color: #24445a;
  }
}
