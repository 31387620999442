.AnimateBTN {
    width: 313px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: #24445A;
    border: none;
    border-radius: 8px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
   }
   
   .AnimateBTN, .AnimateBTN span {
    transition: 200ms;
   }
   
   .AnimateBTN .text {
    color: white;
    font-size: 14px;
    margin-right: 15px;
   }
   
   .AnimateBTN .icon {
    position: absolute;
    border-left: 1px solid white;
    border-radius: 5px;
    transform: translateX(137px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
   }
   
   .AnimateBTN:hover {
    background: white;
   }
   
   .AnimateBTN:hover .text {
    color: transparent;
   }
   
   .AnimateBTN:hover .icon {
    width: 150px;
    border-left: none;
    transform: translateX(-0%);
   }
   
   .AnimateBTN:focus {
    outline: none;
   }

   .AnimateBTN:active{
    background-color: #24445A;
    opacity: 70%;
   }
   .AnimateBTN:active .icon{
    background-color: #24445A;
    opacity: 70%;
   }