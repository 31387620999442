
.slideshow {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  
}

.carousel {
  width: 50rem;
  height: 50rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -55%;
  flex-shrink: 0;
  -webkit-transition: -webkit-transform 1s;
  transition: all 1s ease-in-out;
  margin-left: 120px;
  margin-bottom: 10px;
}

.slide {
  width: 20rem;
  position: absolute;
}

.slide:nth-child(1) {
  left: -20rem;
}

.slide:nth-child(2) {
  bottom: -20rem;
  rotate: 270deg;
}

.slide:nth-child(3) {
  right: -20rem;
  rotate: 180deg;
}

.slide:nth-child(4) {
  top: -20rem;
  rotate: 90deg;
}

.slide.active img {
  margin-left: 50px;
  transform: scale(1.5);
  transition: transform 0.5s 0.5s;
}

.slide img {
  margin-left: 50px;
  width: 100%;
  transition: transform 0.5s;
  /* border: 20px solid #EFF7FB; */
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.100);
  border-radius: 100%;
  background-color: #EFF7FB;
}

.controls {
  position: sticky;
  bottom: 5px;
  cursor: pointer;
  transform: translate(620px,-50px);
}

.controls a {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5rem;
  transition: background-color 1s;
  border: 4px solid #EFF7FB;
  margin-bottom: 10px;
}

.controls a.active {
  background-color: #86BBD8;
}

.controls img {
  width: 3rem;
}
