.grappp{
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.25) inset;
}
::-webkit-scrollbar{
    width: 10px;
  }
  .mini-scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track{
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #24445A;
    border-radius: 100px;
  }