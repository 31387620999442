.wrapper {
  padding: 20px 35px;
    position: relative;
    overflow-x: hidden;
    max-width: 600px;
    /* background: #fff; */
    border-radius: 13px;
  }
  .wrapper .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;
  }
  .icon:first-child {
    left: 0;
    display: none;
  }
  .icon:last-child {
    right: 0;
    justify-content: flex-end;
  }
  .icon i {
    width: 55px;
    height: 55px;
    cursor: pointer;
    font-size: 1.2rem;
    text-align: center;
    line-height: 55px;
    border-radius: 50%;
  }
  .icon i:hover {
    background: #efedfb;
  }
  .icon:first-child i {
    margin-left: 15px;
  } 
  .icon:last-child i {
    margin-right: 15px;
  } 
  .wrapper .tabs-box {
    display: flex;
    gap: 12px;
    list-style: none;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .tabs-box.dragging {
    scroll-behavior: auto;
    cursor: grab;
  }
  .tabs-box .tab {
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    color: #FFF;
    background: #24445A;
    padding: 8px 18px;
    border-radius: 30px;
  }
  /* .tabs-box .tab:hover{
    background: #efedfb;
  } */
  .tabs-box.dragging .tab {
    user-select: none;
    pointer-events: none;
  }
  .tabs-box .tab.active{
    color: #24445A;
    background: #86BBD8;
    border-color: transparent;
  }